/* global graphql */
import React from 'react';
import TemplateWrapper from '../components/TemplateWrapper';
import CalloutUnderline from '../components/Callout/CalloutUnderline';
import InfoSectionBasic from '../components/InfoSection/InfoSectionBasic';
import RainbowTout from '../components/Tout/RainbowTout';
import { graphql } from "gatsby";

const NetworkPage = props =>
  (<TemplateWrapper location={props.location}>
    <main className="NetworkPage">
      <section className="Section NetworkPage-heading">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-12 col-md-offset-1">
                <CalloutUnderline underlineColor="purple" lineOne="Seamless handoff," lineTwo="spectrum agnostic," lineThree="and powered by all of us."/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Section NetworkPage-info1">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-5 col-md-offset-1">
                <hr className="NetworkPage-heading-hr" />
                <p className="Caption-C3 uppercase">How it works</p>
              </div>
            </div>
            <div className="row NetworkPage-info1-content">
              <div className="col-xs-14 col-md-5 col-md-offset-1">
                <h1 className="NetworkPage-info1-title Heading Heading-h1 light">Persistent, performant, and ever-present</h1>
                <p className="BodyText-B1">Magic is a network of networks — seamlessly blending coverage across WiFi, Cellular, and new technologies like 5G and LPWAN.</p>
                <p className="BodyText-B1">The network is built to provide unprecedented coverage alongside device control and privacy. Traffic is encrypted end-to-end and is bad actor tolerant. Devices autonomously negotiate connectivity access based on quality of service and current demand. Provider and client reputation management is baked in to ensure a continuous, tamper proof, trustless environment.</p>
              </div>
              <div className="col-xs-14 col-md-5 col-md-offset-1 NetworkPage-info1-content-p2">
                <p className="BodyText-B1">With Magic, devices can hop between base stations, across networks, and spectrums without skipping a beat. Magic Client is device agnostic — running on smartphones, tablets, wearables and IoT devices. Magic eliminates the need to navigate through the complex, fragmented world of access credentials and billing workflows.</p>
                <p className="BodyText-B1">Devices pay for the data they use. Networks get paid for providing coverage and ensuring data is transported on behalf of Magic enabled devices. Settlement is tracked in immutable ledgers and requires no central clearinghouse to ensure payment.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="magicAgents" className="Section NetworkPage-infoSection1">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-5 col-md-offset-1 NetworkPage-infoSection1-img">
                <img src="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/network-triangles.png" alt="triangles"/>
              </div>
              <div className="col-xs-14 col-md-6 col-md-offset-1">
                <InfoSectionBasic data={props.data.allDataJson.edges[0].node.NetworkInfo1} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="magicClient" className="Section NetworkPage-infoSection1">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 hide-md hide-lg hide-xl NetworkPage-infoSection1-img">
                <img src="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/the-network-client%402x.jpg" alt="circles"/>
              </div>
              <div className="col-xs-14 col-md-6 col-md-offset-1">
                <InfoSectionBasic data={props.data.allDataJson.edges[0].node.NetworkInfo2} />
              </div>
              <div className="col-xs-14 col-md-5 col-md-offset-1 hide-xs hide-sm NetworkPage-infoSection1-img">
                <img src="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/the-network-client%402x.jpg" alt="circles"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="magicToken" className="Section NetworkPage-infoSection1">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-5 col-md-offset-1 NetworkPage-infoSection1-img">
                <img src="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/network-laptop.png" alt="laptop"/>
              </div>
              <div className="col-xs-14 col-md-6 col-md-offset-1">
                <InfoSectionBasic data={props.data.allDataJson.edges[0].node.NetworkInfo3} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Section NetworkPage-rainbow-tout">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-12 col-md-offset-1 col-lg-10 col-lg-offset-2 NetworkPage-rainbow-tout-container">
                <RainbowTout title="Download the Alpha" paragraph1="Magic Alpha is available now for developers. Included is Magic Client and Magic Agent software to enable Magic WiFi using supported routers." paragraph2="Follow along on our GitHub repo and help us define the future of internet access. We can’t wait to hear your feedback." href="/waitlist" btnText="Sign up and Download" trackEvent="Clicked Waitlist CTA"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </TemplateWrapper>);

export default NetworkPage;


export const pageQuery = graphql`
  query NetworkQuery {
    allDataJson {
      edges {
        node {
          NetworkInfo1 {
            title,
            title2,
            paragraph1,
            paragraph2,
            paragraph3,
            paragraph4,
            color
          }
          NetworkInfo2 {
            title,
            title2,
            paragraph1,
            paragraph2,
            paragraph3
            color
          }
          NetworkInfo3 {
            title,
            title2,
            paragraph1,
            paragraph2,
            paragraph3
            color
          }
        }
      }
    }
  }
`;

